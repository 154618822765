import React, { useEffect, useState } from "react";
import { Form, Row, Button } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import dataServices from "../../apiServices/data.services";
import { useAlert } from "react-alert";
import tokenService from "../../apiServices/token.service";
import { handleValidationError } from "../../components/CommonFunctions";

const AddDiagnosis = ({ appointment }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();
  const user = tokenService.getUser();

  const optionsStatus = [
    { value: "COMPLETED", label: "Completed" },
    { value: "CANCLED", label: "Canceled" },
    { value: "CANCLED", label: "Reschedule" },
  ];

  const formik = useFormik({
    initialValues: appointment,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      dataServices
        .editAppointments(id, values)
        .then((res) => {
          if (res.status === 200) {
            if (values.appstatuslabel === "Reschedule") {
              handleRecreateAppointment();
              alert.info("Rescheduling appointment");
            } else {
              alert.success("Diagnosis added successfully");
              navigate("/appointments");
            }
          }
        })
        .catch((err) => {
          alert.error(handleValidationError(err));
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
  });
  const handleGenerateBill = () => {
    navigate("/add-invoice", { state: appointment });
  };
  const handleRecreateAppointment = () => {
    navigate("/request-appointment", { state: appointment });
  };

  return (
    <div>
      <p>Add Diagnosis</p>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mt-2" controlId="diagnosis">
          <Form.Control
            as="textarea"
            rows={3}
            name="diagnosis"
            value={formik.values?.diagnosis || ""}
            disabled={user.role === "STAFF"}
            onChange={formik.handleChange}
            placeholder="Type your Diagnosis here"
            isInvalid={formik.touched.diagnosis && formik.errors.diagnosis}
          />
          {formik.touched.diagnosis && formik.errors.diagnosis ? (
            <Form.Control.Feedback type="invalid">
              {formik.errors.diagnosis}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <br />
        <Row>
          <Form.Group className="mt-2" controlId="diagnosis">
            <Form.Label>Daily note</Form.Label>
            <Form.Control
              type="text"
              name="doctor_note"
              disabled={user.role === "STAFF"}
              value={formik.values?.doctor_note || ""}
              onChange={formik.handleChange}
              placeholder="Note"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mt-2" controlId="diagnosis">
            <Form.Label>Fees</Form.Label>
            <Form.Control
              type="number"
              name="fee"
              disabled={user.role === "STAFF"}
              value={formik.values?.fee || ""}
              onChange={formik.handleChange}
              placeholder="Fee"
              isInvalid={formik.touched.fee && formik.errors.fee}
            />
            {formik.touched.fee && formik.errors.fee ? (
              <Form.Control.Feedback type="invalid">
                {formik.errors.fee}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Row>
        <br />
        <div>
          <Form.Group className="form-group">
            <Form.Label>Select Status</Form.Label>
            <Select
              value={optionsStatus.find(
                (option) => option.value === formik.values?.status || null
              )}
              options={optionsStatus}
              onChange={(option) => {
                formik.setFieldValue("status", option.value);
                formik.setFieldValue("appstatuslabel", option.label);
              }}
            />
          </Form.Group>
        </div>

        {user.role !== "STAFF" && (
          <Button
            variant="primary"
            disabled={loading}
            type="submit"
            className="mt-3 w-100"
          >
            Submit
          </Button>
        )}
        <div className="d-flex">
          {user.role !== "DOCTOR" && (
            <Button
              variant="primary"
              disabled={loading}
              onClick={handleGenerateBill}
              className="mt-3 w-100"
            >
              Generate Invoice
            </Button>
          )}
          <Button
            onClick={handleRecreateAppointment}
            variant="primary"
            className="mt-3 w-100"
          >
            Create Appointment
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddDiagnosis;
